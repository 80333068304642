<template>
  <div class="my-a">
    <div class="my-a-item" v-for="(item, index) in dataList" :key="index">
      <div class="info-item">
        <div class="i-i-1">{{item.type}}</div>
        <div style="margin-right: 70px;">{{item.reson}}</div>
      </div>
      <div class="info-item">
        <div class="i-i-1">{{item.timeLong}}</div>
        <div>{{item.startTime + ' ~ ' + item.endTime}}</div>
      </div>
      <div class="info-status">{{item.statusText}}</div>
    </div>
  </div>
</template>

<script>
import {
  askLeaveRequest
} from '@/api'

export default {
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.dataList = await askLeaveRequest.getMy({
        minSearchTime: new Date().getFullYear() + '-01-01 00:00:00'
      })
      this.dataList.forEach(v => {
        if (v.status === 'end') {
          v.type = v.realType
          v.reson = v.realReson
          v.startTime = v.realStartTime
          v.endTime = v.realEndTime
          v.timeLong = v.realTimeLong
        }
        v.statusText = v.isInvalid ? '已作废' : (v.status === 'end' ? '已销假' : '未销假')
      })
      this.dataList.forEach(v => {
        v.startTime = v.startTime.slice(0, 16)
        v.endTime = v.endTime.slice(0, 16)
      })
    }
  }
}
</script>

<style scoped lang="less">
.info-item {
  display: flex;
  padding: 5px 0;
  .i-i-1 {
    min-width: 80px;
  }
}
.my-a {
  color: #3C3F54;
  height: calc(100% - 40px - 40px);
  max-height: calc(100% - 40px - 40px);
  background: #fff;
  overflow-y: auto;
  padding: 20px;
}
.my-a-item {
  position: relative;
  border-bottom: 1px solid #F0F3F8;
  padding-bottom: 5px;
}
.info-status {
  position: absolute;
  right: 0;
  top: 10px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  background: rgb(240, 243, 248);
}
</style>